export const skillsOptions = [
    { value: 'python', label: 'Python' },
    { value: 'c', label: 'C' },
    { value: 'php', label: 'PHP' },
    { value: 'css3', label: 'CSS' },
    { value: 'html5', label: 'HTML' },
    { value: 'reactjs', label: 'ReactJS' },
    { value: 'mysql', label: 'MySQL' },
    { value: 'mongodb', label: 'MongoDB' },
    { value: 'flask', label: 'Flask' },
    { value: 'figma', label: 'Figma' },
    { value: 'github', label: 'GitHub' },
    { value: 'docker', label: 'Docker' },
    { value: 'restful_api', label: 'RESTful API' },
    { value: 'nginx', label: 'NGINX' },
    { value: 'ubuntu', label: 'Ubuntu' },
    { value: 'arm_assembly', label: 'ARM Assembly' },
    { value: 'node_js', label: 'Node.js' }
].sort((a, b) => a.label.localeCompare(b.label)); // Sort options by label in alphabetical order